import { fragileAllDma50AB } from 'ab_testing'
import { Button } from 'components/Phantom/Button'
import { Icon } from 'components/Phantom/Icon'
import { Type } from 'components/Type'
import { AffirmBoxedLogo } from 'components/_const_assets/affirmLogo'
import { FragileLogo } from 'components/_const_assets/fragileLogo'
import { KlarnaPinkBadge } from 'components/_const_assets/klarnaLogo'
import { Img } from 'components/basic/Img'
import useABTestVariant from 'hooks/useABTestVariant'
import { FC } from 'react'
import { useStore } from 'stores/StoreProvider'
import { useCurrency, usePod4Prices } from 'stores/prices'
import styles from './SplitFeature.module.scss'
import { SplitFeatureProps } from './SplitFeature.types'

export const SplitFeature: FC<SplitFeatureProps> = (props) => {
	const { badge, header, subheader, cta, image, theme, financingProvider, disclaimer, benefits, smallHeader = false } = props

	const ButtonComponent = theme === 'dark' ? Button.White : Button.Dark

	const HeaderComponent = smallHeader ? Type.Headline3 : Type.Headline2

	return (
		<section
			className={styles.container}
			data-theme={theme}
		>
			<div className={styles.wrapper}>
				<header>
					<HeaderComponent className={styles.header}>{header}</HeaderComponent>
					<Type.Body1 className={styles.subheader}>{subheader}</Type.Body1>
					{benefits && (
						<ul className={styles.benefits}>
							{benefits.map((benefit) => (
								<li key={benefit.text}>
									<Icon
										name={benefit.icon}
										color={'black'}
										className={styles.benefit_icon}
									/>
									<Type.Body2>{benefit.text}</Type.Body2>
								</li>
							))}
						</ul>
					)}
					<ButtonComponent
						id={'split-feature-cta'}
						href={cta.href}
						className={styles.cta}
						onClick={cta.onClick}
					>
						{cta.text}
					</ButtonComponent>
					<Type.SmallPrint className={styles.disclaimer}>{disclaimer}</Type.SmallPrint>
				</header>
				<div className={styles.image_container}>
					<Img
						src={image.src}
						alt={image.alt}
						className={styles.image}
						objectFit={'cover'}
					/>
					<div className={styles.image_tag}>
						<FinancingProviderTag financingProvider={financingProvider} />
					</div>
				</div>
			</div>
		</section>
	)
}

const FinancingProviderTag: FC<{ financingProvider?: 'klarna' | 'affirm' | 'fragile' }> = (props) => {
	const { financingProvider } = props

	const getInnerContent = () => {
		switch (financingProvider) {
			case 'klarna':
				return (
					<>
						Powered by <span className={'vh'}>Klarna</span>
						<KlarnaPinkBadge />
					</>
				)
			case 'affirm':
				return (
					<>
						Powered by <span className={'vh'}>Affirm</span>
						<AffirmBoxedLogo />
					</>
				)
			case 'fragile':
				return (
					<>
						Powered by <span className={'vh'}>Fragile</span>
						<FragileLogo
							color={'white'}
							className={styles.fragile_logo}
						/>
					</>
				)
			default:
				return null
		}
	}

	const innerContent = getInnerContent()

	if (!innerContent) return null

	return <Type.Body2 className={styles.financing_tag}>{innerContent}</Type.Body2>
}

export const SplitFeatureRiskFree = () => {
	const settings = useStore((state) => state.settings.settings)
	const saleName = settings.saleName
	const props: SplitFeatureProps = {
		badge: `${saleName}`,
		header: <>Try risk-free for 30&nbsp;nights</>,
		subheader: 'Experience the Pod at home. If it’s not the perfect fit, return it for a full refund.',
		cta: {
			text: 'Shop now',
			href: '/product/pod-cover',
		},
		image: {
			src: 'https://res.cloudinary.com/eightsleep/image/upload/risk-free-boxing-day_qzrq8w.jpg',
			alt: 'A warmly lit room with the central focus being the Pod 4 Ultra and Pod hub.',
		},
		theme: 'white',
	}

	return <SplitFeature {...props} />
}

export const SplitFeatureFinancing = () => {
	const settings = useStore((state) => state.settings.settings)
	const currency = useCurrency()
	const pod4MonthlyPriceFormatted = usePod4Prices().monthlyPriceFormatted

	if (currency !== 'USD') return null
	const saleName = settings.saleName

	const props: SplitFeatureProps = {
		badge: saleName,
		header: `Financing options start from ${pod4MonthlyPriceFormatted}/mo`,
		subheader: `Don’t miss out on our ${saleName}. Choose Affirm to pay in interest-free installments.`,
		cta: {
			text: 'Shop now',
			href: '/product/pod-cover',
		},
		image: {
			src: 'https://res.cloudinary.com/eightsleep/image/upload/risk-free-boxing-day_qzrq8w.jpg',
			alt: 'A warmly lit room with the central focus being the Pod 4 Ultra and Pod hub.',
		},
		financingProvider: 'affirm',
		theme: 'light',
	}

	return <SplitFeature {...props} />
}

export const SplitFeatureFragile = () => {
	const setPaymentOption = useStore((state) => state.shop.setPaymentOption)
	const isFragileAB = useABTestVariant(fragileAllDma50AB, 1)

	if (!isFragileAB) return null

	const props: SplitFeatureProps = {
		badge: '',
		header: `Struggling to commit? Rent the Pod starting at $159/mo`,
		smallHeader: true,
		subheader:
			'Enjoy the Pod 4 + Autopilot for one simple monthly fee. Whether you’re not yet at your forever home, or will be changing beds soon, you can opt to rent the Pod to keep things flexible.',
		cta: {
			text: 'Get started',
			href: '/product/pod-cover',
			onClick: () => {
				setPaymentOption('fragile')
			},
		},
		image: {
			src: 'https://res.cloudinary.com/eightsleep/image/upload/split-feature-fragile_fcsb9e.jpg',
			alt: 'A woman meditating on the Pod 4',
		},
		benefits: [
			{
				icon: 'CalendarLight',
				text: "Rent for as long as you'd like",
			},
			{
				icon: 'ArrowCircleUpLight',
				text: 'Includes upgrades every 2 years*',
			},
			{
				icon: 'CloseCircleLight',
				text: 'Cancel anytime',
			},
			{
				icon: 'WarrantyLight',
				text: 'Ongoing warranty*',
			},
		],
		// financingProvider: 'fragile',
		disclaimer: '*Requires active rental agreement',
		theme: 'light',
	}

	return <SplitFeature {...props} />
}
